import { useState, useEffect } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useWindowDimensions from "../Hooks/useWindowDimensions";
import BarChart from "./Charts/BarChart";

const ColumnChart = (props) => {

  const { windowWidth } = useWindowDimensions();

  const pageId = props.chartId;
  let score = 75;
  let maxScore = 150;
  let chartHeader;
  let chartIdealTxt;
  let difference = 14;

  if (pageId == "699") {

    let siteutilScore = props.variables.find(obj => obj.variable == "siteutil");
    if (siteutilScore) {
      score = siteutilScore.score;
      if (score > 100) {
        maxScore = score;
      }
    }
    chartHeader = `Your site utilization score is <span style="border: 4px solid #FFDD61; border-radius: 50%; padding: 4px 6px;">${Math.round(score)}%</span>`;
    chartIdealTxt = `A Lean Church Facility can achieve 100 seats per acre. <br/><br/>A low score here may indicate room for future growth!`;
    if (Math.round(score) == 100) {
      chartIdealTxt = `A Lean Church Facility can achieve 100 seats per acre.`;
    } else if (Math.round(score) > 100) {
      chartIdealTxt = `A Lean Church Facility can achieve 100 seats per acre. <br/><br/>A score above 100 may indicate you operate in an urban setting or provide off-site parking.`;
    }
    difference = 85 - score;
  } else if (pageId === "705") {
    let usageScore = props.variables.find(obj => obj.variable == "usage");
    if (usageScore) {
      score = usageScore.score;
      if (score > 100) {
        maxScore = score;
      }
    }
    difference = 80 - score;
    chartHeader = `<span style="border: 4px solid #FFDD61; border-radius: 50%; padding: 4px 6px;">${Math.round(score)}%</span> of your building is ministry programmable`;
    if (Math.round(score) >= 80) {
      chartIdealTxt = `Your building is used a lot! <br/>Grab a Red Bull.`;
    } else {
      chartIdealTxt = `A Lean Church needs to specifically design its spaces for adaptability and community use.`;
    }
  }


  const options = {
    chart: {
      type: 'column',
      className: "highcharts-chart",
      width: windowWidth <= 650 ? 250 : 275,
    },
    tooltip: { enabled: false },
    title: {
      // text: `Your site utilization score is ${score2}%`,
      text: null,
    },
    xAxis: {
      width: windowWidth <= 650 ? "80%" : "100%",
      categories: pageId === "705" ? ["Storage"] : ["Site Utilization"],
      title: {
        text: null, // Remove X-axis title
      },
    },
    yAxis: {
      width: windowWidth <= 650 ? "80%" : "100%",
      tickPositions: pageId == 699 ? [0, 85, 100, Math.round(maxScore)] : [0, 80, 100, Math.round(maxScore)],
      gridLineWidth: 0,
      min: 0,
      max: Math.round(maxScore),
      // height: 100,
      lineWidth: 1,
      labels: {
        formatter: function () {
          // Custom label text for the specified tick positions
          if (this.value === 0) return '0%';
          else if (this.value === 80) return '80%';
          else if (this.value === 85) return '85%';
          else if (this.value === 100) return '100%';
          else return ''; // Hide labels for other positions
        },
      },
      plotBands: [{
        from: pageId == 699 ? 85 : 80,
        to: 100,
        color: '#FFDD6123',
        label: {
          // text: 'Last quarter year\'s value range'
        }
      }],
      categories: ['Non Utilization'],
      title: {
        text: null, // Remove X-axis title
      },

    },
    plotOptions: {
      column: {
        borderRadius: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            // display the data value as a percentage inside the column
            return Math.round(score) + '%';
          },
        },
      },
    },
    series: [
      {
        // name: 'X Axis Bar',
        data: [Math.round(score)], // 100% for X Axis Bar
        color: '#FFDD61',
        pointWidth: 50, // Width of X Axis Bar
        pointPadding: 0.5, // Center it on the X-axis
        showInLegend: false,
        states: { hover: { enabled: false } }, // disable hover effect and color change
      },
    ],
    credits: { enabled: false },
  };

  function brOrSpace() {
    if (windowWidth < 831) return " ";
    else return <br />;
  }

  const getScoreTextForMobile = () => {
    if (pageId === "699") {
      chartIdealTxt = <>
        <p style={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }} >
          A Lean Church Facility can <br />
          achieve 100 seats per acre. <br />
          &nbsp; <br />
          A low score here may indicate <br />
          room for future growth! <br />
        </p>
      </>;
      if (Math.round(score) == 100) {
        chartIdealTxt = <>
          <p style={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }} >
            A Lean Church Facility can <br />
            achieve 100 seats per acre. <br />
          </p>
        </>
      } else if (Math.round(score) > 100) {
        chartIdealTxt = <>
          <p style={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }} >
            A Lean Church Facility can <br />
            achieve 100 seats per acre. <br />
            &nbsp; <br />
            A score above 100 may <br />
            indicate you operate in an <br />
            urban setting or provide off- <br />
            site parking. <br />
          </p>
        </>;
      }
    } else if (pageId === "705") {

      if (Math.round(score) >= 80) {
        chartIdealTxt = <>
          <p style={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }} >
            Your building is used a lot! <br />
            Grab a Red Bull.
          </p>
        </>
      } else {
        chartIdealTxt = <>
          <p style={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }} >
            A Lean Church needs to specifically <br />
            design its spaces for adaptability <br />
            and community use.
          </p>
        </>;
      }
    }
    return chartIdealTxt
  }


  

  return (
    <div className="chart-container chart-one">
      <h1 style={{marginBottom: '3rem'}} className="chart-header" dangerouslySetInnerHTML={{ __html: chartHeader }}></h1>
      {
        windowWidth < 831
          ? getScoreTextForMobile()
          : null
      }
      <div className="chart-subcontainer">
        <BarChart
          height={275}
          width={275}
          pageId={pageId}
          max={Math.trunc(maxScore)}
          score={Math.trunc(score)}
          windowWidth={windowWidth}
          bracketMin={pageId == 699 ? 85 : 80}
          bracketMax={100}
          barColor='#FFDD61'
          scoreTextColor='#000'
          bottomAxisText={pageId === "705" ? "Storage" : "Site Utilization"}
        />
        <div>
          {/* <p className="chart-sidetext" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: chartIdealTxt }}></p> */}
        </div>
      </div>
      {
        pageId === "705" ?
          (Math.round(score) == 80 ? null : <p className="chart-subheader"><span>The Lean Church Facility Model recommends</span>{brOrSpace()}studying how you might {difference < 0 ? `decrease` : `increase`} your M.A.R. by {`${Math.round(Math.abs(difference))}%`}.</p>)
          :
          (Math.round(score) == 100 ? null : <p className="chart-subheader"><span>The Lean Church Facility Model recommends</span>{brOrSpace()}a {difference < 0 ? `${-Math.round(difference)}% decrease` : `${Math.round(difference)}% increase`} in your church site utilization.</p>)
      }
    </div>
  );
};

export default ColumnChart;