import { scaleBand, scaleLinear } from 'd3';
import React from 'react'

const StackedBarChart = (props) => {

  const { height, width, pageId, barLeftValue, barRightValue, rightText, windowWidth, barLeftColor = '#464646', barRightColor = '#FFDD61', bottomAxisText, showNote } = props;

  const margin = { top: 20, right: 20, bottom: 25, left: 25 }
  const innerHeight = height - margin.top - margin.bottom
  const innerWidth = width - margin.left - margin.right

  var xScale = scaleBand()
    .domain(pageId === "705" ? ["1", "2", "3", "4", "5"] : ["1", "2", "3", "4", "5"])         // This is what is written on the Axis: from 0 to 100
    .range([0, innerWidth]);       // This is where the axis is placed: from 100px to 800px


  var yScale = scaleLinear()
    .domain([0, barRightValue > barLeftValue ? barRightValue : barLeftValue])         // This is what is written on the Axis: from 0 to 100
    .range([innerHeight, 0])

  console.log(barLeftValue)
  console.log(barRightValue)
  console.log(showNote)

  return (
    <div className="stacked-chart" style={{ margin: '0 auto', height: `${height}px`, width: `${width}px` }} >

      <svg style={{ overflow: 'visible' }} height={height} width={width} >
        <g transform={`translate(${margin.left}, ${margin.top})`}>

          {/* ------------------------ x and y axis ------------------------ */}
          <g className='axis'>
            <line strokeWidth={1} x1={10} y1={innerHeight} x2={innerWidth} y2={innerHeight} stroke="black" />
          </g>

          {/* ------------------------ x and y bars ------------------------ */}
          <g className='bars'>
            <rect fill={barRightColor} y={yScale(barRightValue < 0 ? 0 : barRightValue)} height={innerHeight - yScale(barRightValue < 0 ? 0 : barRightValue)} x={xScale(pageId === "705" ? "3" : "3")} width={xScale.bandwidth()}></rect>
            <rect fill={barLeftValue === barRightValue ? barRightColor : barLeftColor} y={yScale(barLeftValue < 0 ? 0 : barLeftValue)} height={innerHeight - yScale(barLeftValue)} x={xScale(pageId === "705" ? "3" : "3")} width={xScale.bandwidth()}></rect>
            {barLeftValue === barRightValue || barLeftValue < barRightValue ? null : <line x1={92} y1={barRightValue < 0 ? 0 : yScale(barRightValue)} x2={138} y2={barRightValue < 0 ? 0 : yScale(barRightValue)} strokeWidth={2} stroke={barRightColor} />}
            {/* <line x1={0} y1={yScale(350)} x2={innerWidth} y2={yScale(350)} strokeWidth={2} stroke={'black'}  /> */}
          </g>

          {/* ------------------------ score text and arrow ------------------------ */}
          <g transform={`translate(${innerWidth / 2 + 65}, ${yScale(barRightValue <= 0 ? 0 : barRightValue) - 8})`}>
            {
              windowWidth < 831
                ? <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="16px">
                  <image x="0px" y="0px" width="28px" height="16px" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMQAAAA/BAMAAABN3XqCAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAMFBMVEUAAAD/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH///+OrFPEAAAADnRSTlMAIHCAUN8w78+vYBC/n36W0B0AAAABYktHRA8YugDZAAAAB3RJTUUH5wwGCjY4i4efegAAALlJREFUWMPt2MEJwlAQhOEVgmgOwR7sQ1KKNzvI0QZeL2IXXmxD9JKtQQme9OLh/SOBmQIyfIS3sBtRO4ttX/2bH9nlbc82NJk5sBWrV8WdZXSJM6YKlrFOnLE88oyDGWbMkNEkzzjxjNYMM8z4M6NEXLFcporHOZJO4StGviJd8VsE/6Io3oXgdWN5z6gNjiAnrWDQGmHErBCC/YLfkgS7nhFGVIzgqtbhCMWFU3CnFVybBTfz78v/Ez/6MP1GGPh+AAAAAElFTkSuQmCC" />
                </svg>
                : <svg width="103" height="16" viewBox="0 0 103 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M102.707 8.70711C103.098 8.31658 103.098 7.68342 102.707 7.29289L96.3431 0.928932C95.9526 0.538408 95.3195 0.538408 94.9289 0.928932C94.5384 1.31946 94.5384 1.95262 94.9289 2.34315L100.586 8L94.9289 13.6569C94.5384 14.0474 94.5384 14.6805 94.9289 15.0711C95.3195 15.4616 95.9526 15.4616 96.3431 15.0711L102.707 8.70711ZM0 9H102V7H0V9Z" fill="#FFDD61" />
                </svg>
            }
            {rightText}
          </g>
          <g transform={`translate(${windowWidth < 831 ? innerWidth / 2 - 95 : innerWidth / 2 - 170}, ${yScale(barLeftValue < 0 ? 0 : barLeftValue) - 8})`}>
            {
              windowWidth < 831
                ? <svg width="28" height="16" viewBox="0 0 47 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM47 7L1 7V9L47 9V7Z" fill="#FFDD61" />
                </svg>
                : <svg width="103" height="16" viewBox="0 0 103 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.292893 7.29288C-0.0976333 7.68341 -0.0976334 8.31657 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6568L2.41422 7.99999L8.07107 2.34314C8.46159 1.95261 8.46159 1.31945 8.07107 0.928924C7.68054 0.538399 7.04738 0.538399 6.65685 0.928924L0.292893 7.29288ZM103 7L1 6.99999L1 8.99999L103 9L103 7Z" fill="#FFDD61" />
                </svg>
            }
            <text style={{ textAnchor: 'middle', fontSize: windowWidth < 831 ? "13px" : '17px', fontWeight: '600', transform: `translate(${windowWidth < 831 ? "-44px" : "-55px"}, 12px)` }} >Your Church</text>
            {
              showNote && barLeftValue > barRightValue
                ? <text style={{ textAnchor: 'start', fontSize: windowWidth < 831 ? '12px' : '17px', fontWeight: '300', transform: windowWidth < 831 ? 'translate(-80px, 40px)' : 'translate(-100px, 40px)' }} >
                  <tspan style={{ fontWeight: '600' }} x="0" dy="1.2em">Note: </tspan>
                  This may indicate
                  <tspan x="0" dy="1.2em">you don't have all the same </tspan>
                  <tspan x="0" dy="1.2em">spaces as a typical church,</tspan>
                  <tspan x="0" dy="1.2em">i.e., offices, or you do</tspan>
                  <tspan x="0" dy="1.2em">ministry in Southern</tspan>
                  <tspan x="0" dy="1.2em">California and have an</tspan>
                  <tspan x="0" dy="1.2em">outdoor lobby.</tspan>
                </text>
                : null
            }
          </g>

          {/* ------------------------ x and y axis text ------------------------ */}
          <g className='text'>
            <text style={{ textAnchor: 'middle', fontSize: '14px' }} x={innerWidth / 2} y={innerHeight + 20} >{bottomAxisText}</text>
            <text style={{ textAnchor: 'middle', fontSize: '15px', fontWeight: '700' }} x={innerWidth / 2 + 45} y={yScale(barRightValue < 0 ? 15 : barRightValue) + 5} >{Math.trunc(barRightValue)}</text>
            <text style={{ textAnchor: 'middle', fontSize: '15px', fontWeight: '700' }} x={innerWidth / 2 - 45} y={yScale(barLeftValue < 0 ? 15 : barLeftValue) + 5} >{Math.trunc(barLeftValue)}</text>
          </g>
        </g>
      </svg>

    </div>
  )
}

export default StackedBarChart