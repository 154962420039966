import { scaleLinear } from 'd3';
import React, { useEffect, useState } from 'react'

const ProgressBar = (props) => {

  const { ticks, labels, windowWidth } = props;

  const height = 150
  const scaleHeight = windowWidth < 755 ? 13 : 26
  const width = windowWidth < 755 ? 340 : 700
  const margin = { top: 20, right: 20, bottom: 20, left: 20 }
  const innerHeight = height - margin.top - margin.bottom
  const innerWidth = width - margin.left - margin.right

  const [score, setScore] = useState(0)

  const [labelPosition, setLabelPosition] = useState([])
  const [activeLabel, setActiveLabel] = useState([])

  useEffect(() => {

    calculateLabelPosition()

    setTimeout(() => {
      setScore(props.score > 100 ? 100 : props.score)
    }, 150);


  }, [props])

  useEffect(() => {

      getLabelsFontWeight()

  }, [score, props])

  let xScale = scaleLinear()
    .domain([0, 100])
    .range([0, innerWidth])

  const calculateLabelPosition = () => {
    let arr = []

    for (let i = 1; i < ticks?.length; i++) {
      let labelPosition = ticks[i - 1] + ticks[i]
      arr.push(labelPosition / 2)
    }

    setLabelPosition(arr)
  }


  const getLabelsPosition = (index) => {
    return labelPosition[index]
  }

  const getLabelsFontWeight = (label, i) => {

    for (let i = 0; i < ticks?.length; i++) {
      if (score >= ticks[i] && score <= ticks[i + 1]) {
        setActiveLabel(labels[i])
      }
    }

  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
      <svg style={{ overflow: 'visible' }} height={innerHeight} width={innerWidth} >

        <defs>
          <linearGradient id="gradient" x1="0" x2="1" y1="0" y2="0">
            <stop offset="0%" stop-color="#FFF4CE" />
            <stop offset="100%" stop-color="#FFDD62" />
          </linearGradient>
          <clipPath id="round-corner">
            <rect id="rect1" rx="15" width={xScale(100)} height={scaleHeight} />
          </clipPath>
        </defs>

        <g>
          <g className='scale'>
            <rect id="rect1" rx="15" fill="#AEAEAE33" width={xScale(100)} height={scaleHeight} />
            <rect style={{ transition: 'all 450ms ease' }} id="rect1" clipPath='url(#round-corner)' fill="url(#gradient)" width={xScale(score)} height={scaleHeight} />
          </g>

          <g style={{ transition: 'all 450ms ease' }} className='score-indicator' transform={`translate(${xScale(score) - (windowWidth < 755 ? 20 : 27)}, ${windowWidth < 755 ? -40 : -55})`} >
            <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
              <path style={{ transform: windowWidth < 755 ? 'scale(1.2)' : 'scale(1.7)' }} fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.895428 0 2V20.3346C0 21.4392 0.895431 22.3346 2 22.3346H31.0195C32.1241 22.3346 33.0195 21.4392 33.0195 20.3346V2C33.0195 0.895431 32.1241 0 31.0195 0H2ZM12.1627 22.335L16.2478 27.6953L20.3329 22.335H12.1627Z" fill="black" />
            </svg>
          </g>

          <g transform={`translate(${xScale(score)}, ${windowWidth < 755 ? -20 : -27})`} style={{ transition: 'all 450ms ease' }} className='score-indicator'  >
            <text fill='#FFDD62' style={{ textAnchor: 'middle', fontSize: windowWidth < 755 ? '17px' : '22px', fontWeight: '700' }}  >{score}%</text>
          </g>

          <g className='ticks'>
            {
              ticks?.map((tick, i) => {
                if (tick == 100 || tick == 0) return null;
                return <line key={i} x1={xScale(tick)} y1="0" x2={xScale(tick)} y2={scaleHeight} strokeWidth={3} stroke="white" />
              })
            }
            {
              ticks?.map((tick, i) => {
                return <text key={i} style={{ textAnchor: 'middle', fontSize: windowWidth < 755 ? '10px' : '13px', fontWeight: '700' }} x={xScale(tick)} y={windowWidth < 755 ? 30 : 40} >{tick}%</text>
              })
            }
          </g>

          <g className='labels'>
            {
              labels?.map((label, i) => {
                return <text fill={activeLabel == label ? 'black' : '#575757'} key={i} style={{ textAnchor: 'middle', fontSize: windowWidth < 755 ? '10px' : '17px', fontWeight: activeLabel == label ? 700 : 400 }} x={xScale(getLabelsPosition(i))} y={windowWidth < 755 ? 45 : 60} >{label}</text>
              })
            }
          </g>

        </g>

      </svg>
    </div>
  )
}

export default ProgressBar