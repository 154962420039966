import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import useWindowDimensions from '../Hooks/useWindowDimensions';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';
import leftArrowImg from '../Assets/Images/left-arrow.png';
import rightArrowImg from '../Assets/Images/right-arrow.png';
import leftArrowMobileImg from '../Assets/Images/left-arrow-mobile.png';
import rightArrowMobileImg from '../Assets/Images/right-arrow-mobile.png';

import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import { axisBottom, line, scaleBand, scaleLinear, select } from 'd3';
import BarChart from '../Components/Charts/BarChart';
import SingleBarChart from '../Components/Charts/SingleBarChart';
import MultipleBarChart from '../Components/Charts/MultipleBarChart';
import StackedBarChart from '../Components/Charts/StackedBarChart';
// import * as d3 from 'd3';


const CustomColumnCharts = () => {

  const { windowWidth } = useWindowDimensions();
  const { id, pageId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [variables, setVariables] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {

    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        console.log(data);
        setVariables(data.data.variables);
        setIsLoading(false);
        return;
      } else {
        console.log("SOMETHING WENT WRONG");
        return;
      }
    }).catch((err) => console.error("Error while fetching form questions...", err));


  }, [id, pageId]);

  const renderCharts = () => {
    switch (pageId) {
      case "699": return <ColumnChart key={699} variables={variables} />
      case "701": return <CustomStackedColumnChart key={701} variables={variables} />
      case "703": return <TextPage variables={variables} />
      case "705": return <ColumnChart key={705} variables={variables} />
      case "707": return <CustomStackedColumnChart key={707} variables={variables} />
      default: return null;
    }
  }

  const prevPage = () => {
    if (pageId && (pageId >= 699 && pageId <= 707)) {
      navigate("/questions/" + id + "/" + (parseInt(pageId) - 1) + "?" + searchParams);
    }
  }

  const nextPage = () => {
    if (pageId == 707) {
      navigate("/details/" + id + "/708?" + searchParams);
    } else {
      navigate("/questions/" + id + "/" + (parseInt(pageId) + 1) + "/?" + searchParams);
    }
  }

  if (isLoading) {
    return <div className="custom-column-charts-container loading"><CircularProgress style={{ color: '#FFDD61' }} size={60} /></div>
  }

  return (
    <div className="custom-column-charts-container">

      {renderCharts()}

      {
        windowWidth > 830 ? <div className="step-buttons">
          <Button className="cta-prev" onClick={() => prevPage()}><img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV</Button>
          <Button className="cta" onClick={() => nextPage()}><span className="next-btn">NEXT</span></Button>
        </div> : <div className="step-buttons">
          <Button className="cta-prev" onClick={() => prevPage()}><img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;</Button>
          <Button className="cta" onClick={() => nextPage()}><span className="next-btn">NEXT</span></Button>
        </div>
      }

    </div>
  )
}

const ColumnChart = (props) => {

  const { windowWidth } = useWindowDimensions();

  const { pageId } = useParams();
  let score = 75;
  let maxScore = 120;
  let chartHeader;
  let chartIdealTxt;
  let difference = 14;

  if (pageId === "699") {

    let siteutilScore = props.variables.find(obj => obj.variable == "siteutil");
    if (siteutilScore) {
      score = siteutilScore.score;
      if (score > 100) {
        maxScore = score;
      }
    }
    chartHeader = `Your site utilization score is <span style="border: 4px solid #FFDD61; border-radius: 50%; padding: 4px 6px;">${Math.round(score)}%</span>`;
    chartIdealTxt = `A Lean Church Facility can achieve 100 seats per acre. <br/><br/>A low score here may indicate room for future growth!`;
    if (Math.round(score) == 100) {
      chartIdealTxt = `A Lean Church Facility can achieve 100 seats per acre.`;
    } else if (Math.round(score) > 100) {
      chartIdealTxt = `A Lean Church Facility can achieve 100 seats per acre. <br/><br/>A score above 100 may indicate you operate in an urban setting or provide off-site parking.`;
    }
    difference = 85 - score;
  } else if (pageId === "705") {
    let usageScore = props.variables.find(obj => obj.variable == "usage");
    if (usageScore) {
      score = usageScore.score;
      if (score > 100) {
        maxScore = score;
      }
    }
    difference = 80 - score;
    chartHeader = `<span style="border: 4px solid #FFDD61; border-radius: 50%; padding: 4px 6px;">${Math.round(score)}%</span> of your building is ministry programmable`;
    if (Math.round(score) >= 80) {
      chartIdealTxt = `Your building is used a lot! <br/>Grab a Red Bull.`;
    } else {
      chartIdealTxt = `A Lean Church needs to specifically design its spaces for adaptability and community use.`;
    }
  }

  const getScoreTextForSvg = () => {
    if (pageId === "699") {
      chartIdealTxt = <>
        <text transform={`translate(${150}, ${-120})`} style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300' }} >
          <tspan x="0" dy="1.2em">A Lean Church Facility can</tspan>
          <tspan x="0" dy="1.2em">achieve 100 seats per acre.</tspan>
          <tspan x="0" dy="1.2em">&nbsp;</tspan>
          <tspan x="0" dy="1.2em">A low score here may indicate</tspan>
          <tspan x="0" dy="1.2em">room for future growth!</tspan>
        </text>
      </>;
      if (Math.round(score) == 100) {
        chartIdealTxt = <>
          <text transform={`translate(${150}, ${-50})`} style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300' }} >
            <tspan x="0" dy="1.2em">A Lean Church Facility can</tspan>
            <tspan x="0" dy="1.2em">achieve 100 seats per acre.</tspan>
          </text>
        </>
      } else if (Math.round(score) > 100) {
        chartIdealTxt = <>
          <text transform={`translate(${150}, ${-150})`} style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300' }} >
            <tspan x="0" dy="1.2em">A Lean Church Facility can</tspan>
            <tspan x="0" dy="1.2em">achieve 100 seats per acre.</tspan>
            <tspan x="0" dy="1.2em">&nbsp;</tspan>
            <tspan x="0" dy="1.2em">A score above 100 may</tspan>
            <tspan x="0" dy="1.2em">indicate you operate in an</tspan>
            <tspan x="0" dy="1.2em">urban setting or provide off-</tspan>
            <tspan x="0" dy="1.2em">site parking.</tspan>
          </text>
        </>;
      }
    } else if (pageId === "705") {

      if (Math.round(score) >= 80) {
        chartIdealTxt = <>
          <text transform={`translate(${150}, ${-50})`} style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300' }} >
            <tspan x="0" dy="1.2em">Your building is used a lot!</tspan>
            <tspan x="0" dy="1.2em">Grab a Red Bull.</tspan>
          </text>
        </>
      } else {
        chartIdealTxt = <>
          <text transform={`translate(${150}, ${-70})`} style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300' }} >
            <tspan x="0" dy="1.2em">A Lean Church needs to specifically</tspan>
            <tspan x="0" dy="1.2em">design its spaces for adaptability</tspan>
            <tspan x="0" dy="1.2em">and community use.</tspan>
          </text>
        </>;
      }
    }
    return chartIdealTxt
  }

  const getScoreTextForMobile = () => {
    if (pageId === "699") {
      chartIdealTxt = <>
        <p style={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }} >
          A Lean Church Facility can <br />
          achieve 100 seats per acre. <br />
          &nbsp; <br />
          A low score here may indicate <br />
          room for future growth! <br />
        </p>
      </>;
      if (Math.round(score) == 100) {
        chartIdealTxt = <>
          <p style={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }} >
            A Lean Church Facility can <br />
            achieve 100 seats per acre. <br />
          </p>
        </>
      } else if (Math.round(score) > 100) {
        chartIdealTxt = <>
          <p style={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }} >
            A Lean Church Facility can <br />
            achieve 100 seats per acre. <br />
            &nbsp; <br />
            A score above 100 may <br />
            indicate you operate in an <br />
            urban setting or provide off- <br />
            site parking. <br />
          </p>
        </>;
      }
    } else if (pageId === "705") {

      if (Math.round(score) >= 80) {
        chartIdealTxt = <>
          <p style={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }} >
            Your building is used a lot! <br />
            Grab a Red Bull.
          </p>
        </>
      } else {
        chartIdealTxt = <>
          <p style={{ fontSize: '16px', fontWeight: '300', textAlign: 'center' }} >
            A Lean Church needs to specifically <br />
            design its spaces for adaptability <br />
            and community use.
          </p>
        </>;
      }
    }
    return chartIdealTxt
  }


  function brOrSpace() {
    if (windowWidth < 831) return " ";
    else return <br />;
  }


  console.log(maxScore)

  return (
    <div className="chart-container chart-one">
      <h1 className="chart-header" dangerouslySetInnerHTML={{ __html: chartHeader }}></h1>
      {
        windowWidth < 831
          ? getScoreTextForMobile()
          : null
      }
      <div className="chart-subcontainer">
        {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}

        <BarChart
          height={275}
          width={275}
          pageId={pageId}
          max={maxScore}
          score={score}
          windowWidth={windowWidth}
          bracketMin={pageId == 699 ? 85 : 80}
          bracketMax={100}
          barColor='#FFDD61'
          scoreTextColor='#000'
          bottomAxisText={pageId === "705" ? "Storage" : "Site Utilization"}
        />
        {/* <SingleBarChart
          height={275}
          width={275}
          pageId={pageId}
          max={maxScore}
          score={score}
          windowWidth={windowWidth}
          bracketMin={pageId == 699 ? 85 : 80}
          bracketMax={100}
          barColor='#FFDD61'
          scoreTextColor='#000'
          bottomAxisText={pageId === "705" ? "Storage" : "Site Utilization"}
        /> */}

      </div>
      {
        pageId === "705"
          ? (Math.round(score) == 80 ? null : <p className="chart-subheader"><span>The Lean Church Facility Model recommends</span>{brOrSpace()}studying how you might {difference < 0 ? `decrease` : `increase`} your M.A.R. by {`${Math.round(Math.abs(difference))}%`}.</p>)
          : (Math.round(score) == 100 ? null : <p className="chart-subheader"><span>The Lean Church Facility Model recommends</span>{brOrSpace()}a {difference < 0 ? `${-Math.round(difference)}% decrease` : `${Math.round(difference)}% increase`} in your church site utilization.</p>)
      }
    </div>
  );
};


const CustomStackedColumnChart = (props) => {

  console.log(props);

  const { pageId } = useParams();
  const { windowWidth } = useWindowDimensions();
  const [rightArrow, setRightArrow] = useState(rightArrowImg);
  const [leftArrow, setLeftArrow] = useState(leftArrowImg);
  const [rightText, setRightText] = useState("");
  const [leftText, setLeftText] = useState("Your church");
  const [chartScore, setChartScore] = useState(0);
  const [chartTotal, setChartTotal] = useState(0);


  useEffect(() => {
    if (windowWidth < 831) {
      setRightArrow(rightArrowMobileImg);
      setLeftArrow(leftArrowMobileImg);
      setRightText(<text style={{ textAnchor: 'middle', fontSize: windowWidth < 831 ? '13px' : '17px', fontWeight: windowWidth < 831 ? '600' : '300', transform: `translate(${windowWidth < 831 ? "74px, -4px" : "220px, -15px"} )` }} >
        <tspan x="0" dy="1.2em">A Lean Church</tspan>
      </text>);
      setLeftText("Your church");
    } else {
      setRightArrow(rightArrowImg);
      setLeftArrow(leftArrowImg);
      if (pageId === "701") {
        let persqft = 45;
        let persqftScore = props.variables.find(obj => obj.variable == "persqft");
        if (persqftScore) {
          persqft = persqftScore.score;
        }
        // console.log("persqft", persqft);
        setRightText(<text style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300', transform: 'translate(220px, -15px)' }} >
          <tspan x="0" dy="1.2em">A Lean Church needs around</tspan>
          <tspan x="0" dy="1.2em">{persqft} square feet per seat.</tspan>
        </text>);
      } else if (pageId === "707") {
        setRightText(<text style={{ textAnchor: 'start', fontSize: '17px', fontWeight: '300', transform: 'translate(130px, -15px)' }} >
          <tspan x="0" dy="1.2em">A Lean Church needs 2 seats</tspan>
          <tspan x="0" dy="1.2em">per parking spot.</tspan>
        </text>);
      }
    }

    if (pageId === "701") {

      let seatsleanScore = props.variables.find(obj => obj.variable == "seatslean");
      if (seatsleanScore) {
        seatslean = seatsleanScore.score;
        setChartTotal(seatslean)
      }

      let seatsScore = props.variables.find(obj => obj.variable == "seats");
      if (seatsScore) {
        seats = seatsScore.score;
        setChartScore(seats)
      }


    } else if (pageId === "707") {

      let spot;
      let leanspot;

      let spotScore = props.variables.find(obj => obj.variable == "spot");
      if (spotScore) {
        spot = spotScore.score;
        setChartScore(spot)
      }

      let leanspotScore = props.variables.find(obj => obj.variable == "leanspot");
      if (leanspotScore) {
        leanspot = leanspotScore.score;
        setChartTotal(leanspot)
      }

    }

  }, [windowWidth, pageId, props.variables]);

  let sqftperseat = 45;
  let persqft = 45;
  let seatslean = 100;
  let seats = 75;

  let score = 75;
  let total = 100;

  let difference = total - score;
  let scorePercent = (score / total) * 100;
  // score bar is 200px height & score is in % so multiple by 2 to get px height for scoreBar
  let scoreBar = scorePercent * 2;
  let differencePercent = (difference / total) * 100;
  // difference bar is 200px height & score is in % so multiple by 2 to get px height for differenceBar
  let differenceBar = differencePercent * 2;

  let showParkingScoreDisclaimer = false;
  let showSeatingScoreNote = false;

  let chartHeader;
  let chartSubHeader;
  let chartBottomText;
  let scoreGreaterThanTotal = false;
  let scoreEqualToTotal = false;
  // score based text changes
  if (pageId === "701") {

    let sqftperseatScore = props.variables.find(obj => obj.variable == "sqftperseat");
    if (sqftperseatScore) {
      sqftperseat = sqftperseatScore.score;
    }

    let seatsleanScore = props.variables.find(obj => obj.variable == "seatslean");
    if (seatsleanScore) {
      seatslean = seatsleanScore.score;
    }

    let seatsScore = props.variables.find(obj => obj.variable == "seats");
    if (seatsScore) {
      seats = seatsScore.score;
    }

    let persqftScore = props.variables.find(obj => obj.variable == "persqft");
    if (persqftScore) {
      persqft = persqftScore.score;
    }
    console.log(persqft);

    score = seats;
    total = seatslean;
    // if score > total then swap values for chart correction
    // otherwise percentages will be wrong since it is score / total * 100 
    if (seats > seatslean) {
      score = seatslean;
      total = seats;
      scoreGreaterThanTotal = true;
    } else {
      scoreGreaterThanTotal = false;
    }
    scoreEqualToTotal = total == score;
    difference = total - score;
    scorePercent = (score / total) * 100;
    // score bar is 200px height & score is in % so multiple by 2 to get px height for scoreBar
    differencePercent = (difference / total) * 100;
    // difference bar is 200px height & score is in % so multiple by 2 to get px height for differenceBar
    scoreBar = scorePercent * 2;
    differenceBar = differencePercent * 2;

    // console.log("score", score); console.log("total", total);
    // console.log("difference", difference); console.log("scorePercent", scorePercent);
    // console.log("differencePercent", differencePercent);

    chartBottomText = "Number of Seats";
    chartHeader = `Your church uses <span style="border: 4px solid #FFDD61; border-radius: 50%; padding: 4px 6px;">${Math.round(sqftperseat)}</span> sq ft per seat`;
    if (difference > 0 && !scoreGreaterThanTotal) {
      chartSubHeader = `<span>As per the Lean Facility Model,</span><br/>a more efficient building could help accommodate ${Math.round(difference)} more seats.`;
    } else if (scoreGreaterThanTotal && difference > 0) {
      showSeatingScoreNote = true;
      chartSubHeader = `<span>In a Lean Facility Model,</span><br/>you would have ${Math.round(difference)} fewer seats.`;
    } else {
      chartSubHeader = "";
    }
    if (windowWidth < 831) chartSubHeader = chartSubHeader.replaceAll("<br/>", " ");
  } else if (pageId === "707") {

    let spot;
    let leanspot;

    let spotScore = props.variables.find(obj => obj.variable == "spot");
    if (spotScore) {
      spot = spotScore.score;
    }

    let leanspotScore = props.variables.find(obj => obj.variable == "leanspot");
    if (leanspotScore) {
      leanspot = leanspotScore.score;
    }

    score = spot;
    total = leanspot;
    // if score > total then swap values for chart correction
    // otherwise percentages will be wrong since it is score / total * 100 
    if (spot > leanspot) {
      score = leanspot;
      total = spot;
      scoreGreaterThanTotal = true;
    } else {
      scoreGreaterThanTotal = false;
    }
    scoreEqualToTotal = total == score;
    difference = total - score;
    scorePercent = (score / total) * 100;
    // score bar is 200px height & score is in % so multiple by 2 to get px height for scoreBar
    scoreBar = scorePercent * 2;
    differencePercent = (difference / total) * 100;
    // difference bar is 200px height & score is in % so multiple by 2 to get px height for differenceBar
    differenceBar = differencePercent * 2;

    console.log("score", score); console.log("total", total);
    console.log("difference", difference); console.log("scorePercent", scorePercent);
    console.log("differencePercent", differencePercent);

    chartBottomText = "Number of Parking Spots";
    if (difference > 0 && !scoreGreaterThanTotal) {
      showParkingScoreDisclaimer = true;
      chartHeader = "You do not have enough parking spots.";
      chartSubHeader = `You need to add ${Math.round(difference)} more parking spots<br/><span style="font-weight: 400;">in order to maximize your seat count.*</span>`;
    } else if (scoreGreaterThanTotal && difference > 0) {
      chartHeader = "You have more parking than you need.";
      chartSubHeader = `<span style="font-weight: 400;">You have more parking than you need and </span><br/>can add ${Math.round(difference)} more seats before it becomes a lid to growth.`;
    } else {
      chartHeader = "You have the perfect amount of parking.";
      chartSubHeader = ``;
    }
    if (windowWidth < 831) chartSubHeader = chartSubHeader.replaceAll("<br/>", " ");
  }


  const getMobileTextForChart = () => {
    if (pageId === "701") {
      let persqft = 45;
      let persqftScore = props.variables.find(obj => obj.variable == "persqft");
      if (persqftScore) {
        persqft = persqftScore.score;
      }
      // console.log("persqft", persqft);
      return (<p style={{ fontSize: '17px', fontWeight: '300', textAlign: 'center' }} >
        A Lean Church needs around <br />
        {persqft} square feet per seat.
      </p>);
    } else if (pageId === "707") {
      return (<p style={{ fontSize: '17px', fontWeight: '300', textAlign: 'center' }} >
        A Lean Church needs 2 seats <br />
        per parking spot.
      </p>);
    }
  }

  // console.log(chartScore)
  console.log(showSeatingScoreNote)

  return (
    <div style={{ margin: "15px auto" }}>

      {/* Total Score Bar with Right Text */}
      <div className="chart-container custom-chart-container" style={{ padding: "30px 15px 5px" }}>
        <h2 dangerouslySetInnerHTML={{ __html: chartHeader }}></h2>
        {/* Score Bar with Left Text */}

        {/* <div className="flex-container" style={{ height: "200px", flexDirection: scoreGreaterThanTotal ? "column-reverse" : "column" }}>
          <div className="score-bar" style={{ height: `${scoreGreaterThanTotal ? scoreBar : differenceBar}px`, backgroundColor: scoreEqualToTotal ? "#FFDD61" : scoreGreaterThanTotal ? "#464646" : "#FFDD61", position: "relative", borderTop: scoreGreaterThanTotal ? (pageId == 707 ? "2px solid #FFDD61" : "2px dashed #FFDD61") : "none" }}>
            <div className="right-side-info">
              <div className="score-arrow">
                <span>{scoreGreaterThanTotal ? Math.round(score) : Math.round(total)}</span>&nbsp;&nbsp;<img src={rightArrow} alt="right arrow" height="12px" width="auto" />
              </div>
              <div className="score-text">
                <span className="chart-side-text light-weight">{rightText}</span></div>
            </div>
          </div>

          <div className="score-bar" style={{ height: `${scoreGreaterThanTotal ? differenceBar : scoreBar}px`, backgroundColor: scoreEqualToTotal ? "#FFDD61" : "#464646", position: "relative" }}>
            <div className="left-side-info">
              <div className="score-arrow">
                <img src={leftArrow} alt="left arrow" height="12px" width="auto" /> <span>{scoreGreaterThanTotal ? Math.round(total) : Math.round(score)}</span>
              </div>
              <div className="score-text"><span className="chart-side-text" dangerouslySetInnerHTML={{ __html: leftText }}></span></div>
              {showSeatingScoreNote &&
                <div className="score-note">
                  <strong>Note:</strong> This may indicate you don't have all the same spaces as a typical church, i.e., offices, or you do ministry in Southern California and have an outdoor lobby.
                </div>}
            </div>
          </div>
        </div> */}
        {/* <p className="chart-bottom-text">{chartBottomText}</p> */}
        {/* Score Bar with Left Text */}

        {/* <div className="stacked-chart" style={{ margin: '0 auto', height: `${height}px`, width: `${width}px` }} >

          <svg style={{ overflow: 'visible' }} height={height} width={width} >
            <g transform={`translate(${margin.left}, ${margin.top})`}>

              <g className='axis'>
                <line strokeWidth={1} x1={10} y1={innerHeight - 10} x2={innerWidth} y2={innerHeight - 10} stroke="black" />
              </g>

              <g className='bars'>
                <rect fill='#FFDD61' y={yScale(chartTotal < 0 ? 0 : chartTotal)} height={innerHeight - yScale(chartTotal < 0 ? 0 : chartTotal) - 10} x={xScale(pageId === "705" ? "3" : "3")} width={xScale.bandwidth()}></rect>
                <rect fill={chartScore == chartTotal ? '#FFDD61' : '#464646'} y={yScale(chartScore < 0 ? 0 : chartScore)} height={innerHeight - yScale(chartScore) - 10} x={xScale(pageId === "705" ? "3" : "3")} width={xScale.bandwidth()}></rect>
                {chartScore == chartTotal || chartScore < chartTotal ? null : <line x1={66} y1={chartTotal < 0 ? innerHeight - 10 : yScale(chartTotal)} x2={99} y2={chartTotal < 0 ? innerHeight - 10 : yScale(chartTotal)} strokeWidth={2} stroke="#FFDD61" stroke-dasharray="3" />}
              </g>

              <g transform={`translate(${innerWidth / 2 + 50}, ${chartTotal < 0 ? innerHeight - 20 : yScale(chartTotal) - 8})`}>
                {
                  windowWidth < 831
                    ? <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28px" height="16px">
                      <image x="0px" y="0px" width="28px" height="16px" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMQAAAA/BAMAAABN3XqCAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAMFBMVEUAAAD/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH/3WH///+OrFPEAAAADnRSTlMAIHCAUN8w78+vYBC/n36W0B0AAAABYktHRA8YugDZAAAAB3RJTUUH5wwGCjY4i4efegAAALlJREFUWMPt2MEJwlAQhOEVgmgOwR7sQ1KKNzvI0QZeL2IXXmxD9JKtQQme9OLh/SOBmQIyfIS3sBtRO4ttX/2bH9nlbc82NJk5sBWrV8WdZXSJM6YKlrFOnLE88oyDGWbMkNEkzzjxjNYMM8z4M6NEXLFcporHOZJO4StGviJd8VsE/6Io3oXgdWN5z6gNjiAnrWDQGmHErBCC/YLfkgS7nhFGVIzgqtbhCMWFU3CnFVybBTfz78v/Ez/6MP1GGPh+AAAAAElFTkSuQmCC" />
                    </svg>
                    : <svg width="103" height="16" viewBox="0 0 103 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M102.707 8.70711C103.098 8.31658 103.098 7.68342 102.707 7.29289L96.3431 0.928932C95.9526 0.538408 95.3195 0.538408 94.9289 0.928932C94.5384 1.31946 94.5384 1.95262 94.9289 2.34315L100.586 8L94.9289 13.6569C94.5384 14.0474 94.5384 14.6805 94.9289 15.0711C95.3195 15.4616 95.9526 15.4616 96.3431 15.0711L102.707 8.70711ZM0 9H102V7H0V9Z" fill="#FFDD61" />
                    </svg>
                }
                {rightText}
              </g>
              <g transform={`translate(${windowWidth < 831 ? innerWidth / 2 + -80 : innerWidth / 2 + -154}, ${yScale(chartScore < 0 ? 0 : chartScore) - 8})`}>
                {
                  windowWidth < 831
                    ? <svg width="28" height="16" viewBox="0 0 47 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM47 7L1 7V9L47 9V7Z" fill="#FFDD61" />
                    </svg>
                    : <svg width="103" height="16" viewBox="0 0 103 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.292893 7.29288C-0.0976333 7.68341 -0.0976334 8.31657 0.292892 8.7071L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6568L2.41422 7.99999L8.07107 2.34314C8.46159 1.95261 8.46159 1.31945 8.07107 0.928924C7.68054 0.538399 7.04738 0.538399 6.65685 0.928924L0.292893 7.29288ZM103 7L1 6.99999L1 8.99999L103 9L103 7Z" fill="#FFDD61" />
                    </svg>
                }

                <text style={{ textAnchor: 'middle', fontSize: windowWidth < 831 ? "13px" : '18px', fontWeight: '600', transform: `translate(${windowWidth < 831 ? "-39px" : "-55px"}, 12px)` }} >Your Church</text>
                {
                  showSeatingScoreNote &&
                  <text style={{ textAnchor: 'start', fontSize: windowWidth < 831 ? '12px' : '17px', fontWeight: '300', transform: windowWidth < 831 ? 'translate(-80px, 40px)' : 'translate(-100px, 40px)' }} >
                    <tspan style={{ fontWeight: '600' }} x="0" dy="1.2em">Note: </tspan>
                    This may indicate
                    <tspan x="0" dy="1.2em">you don't have all the same </tspan>
                    <tspan x="0" dy="1.2em">spaces as a typical church,</tspan>
                    <tspan x="0" dy="1.2em">i.e., offices, or you do</tspan>
                    <tspan x="0" dy="1.2em">ministry in Southern</tspan>
                    <tspan x="0" dy="1.2em">California and have an</tspan>
                    <tspan x="0" dy="1.2em">outdoor lobby.</tspan>
                  </text>
                }
              </g>

              <g className='text'>
                <text style={{ textAnchor: 'middle', fontSize: '14px' }} x={innerWidth / 2} y={innerHeight + 6} >{pageId === "701" ? "Number of Seats" : "Number of Parking Spots"}</text>
                <text style={{ textAnchor: 'middle', fontSize: '15px', fontWeight: '700' }} x={innerWidth / 2 + 35} y={chartTotal < 0 ? innerHeight - 10 : yScale(chartTotal) + 6} >{chartTotal}</text>
                <text style={{ textAnchor: 'middle', fontSize: '15px', fontWeight: '700' }} x={innerWidth / 2 + -35} y={yScale(chartScore < 0 ? 5 : chartScore) + 6} >{chartScore}</text>
              </g>
            </g>
          </svg>

        </div> */}

        {windowWidth < 831 ? getMobileTextForChart() : null}

        <StackedBarChart
          height={275}
          width={275}
          pageId={pageId}
          barLeftValue={chartScore}
          barRightValue={chartTotal}
          windowWidth={windowWidth}
          barLeftColor='#464646'
          barRightColor='#FFDD61'
          rightText={rightText}
          bottomAxisText={pageId === "701" ? "Number of Seats" : "Number of Parking Spots"}
          showNote={pageId === "701"}
        />

        {chartSubHeader == "" ? null : <p className="chart-subheader" style={{ fontWeight: pageId == 707 ? 700 : 400 }} dangerouslySetInnerHTML={{ __html: chartSubHeader }}></p>}
        {showParkingScoreDisclaimer ? <p style={{ fontSize: "18px", lineHeight: "25px", fontWeight: 300, margin: "2px auto", textAlign: "center" }}>*Urban churches with public transit can violate this rule.</p> : null}
      </div>


    </div >
  );

}


const TextPage = (props) => {

  const { windowWidth } = useWindowDimensions();
  let pageHeader = "";
  let mainText = "";
  let pageSubHeader = "";

  let score = 0;
  let storageScore = props.variables.find(obj => obj.variable == "store");
  if (storageScore) {
    score = storageScore.score;
  }

  if (score == 7) {
    pageHeader = "You have unlocked a superpower — please write a book so other churches can learn from your expertise.";
    mainText = "A Lean church has enough storage space in key locations that rooms and spaces function effectively.";
  } else if (score == 8) {
    pageHeader = "You may not have enough storage or you may be storing the wrong stuff.";
    mainText = "The Lean Facility Indicator recommends an audit of your stored items for how frequently they are used.";
  } else if (score == 9) {
    pageHeader = "You may not have enough storage or you may be storing the wrong stuff.";
    mainText = "The Lean Facility Indicator recommends an audit of your stored items for how frequently they are used.";
  } else if (score == 10) {
    pageHeader = "Get the junk out!";
    mainText = "The Lean facility indicator recommends storing items that are not frequently used off-site.";
  }

  function brOrSpace() {
    if (windowWidth < 831) return " ";
    else return <br />;
  }

  return (
    <div className="chart-container text-page">
      <h1 className="chart-header" style={{ maxWidth: "42ch" }}>{pageHeader}</h1>
      <div className="text-container">
        <div className="text-subcontainer">
          <p className="chart-main-text">{mainText}</p>
        </div>
      </div>

      {/* <p className="chart-subheader">A Lean Church needs to dedicate approx.<br/><span>{score}%</span> of its square footage to storage.</p> */}
      <p className="chart-subheader">A Lean Church needs to dedicate approx.{brOrSpace()}<strong>7%</strong> of its square footage to storage.</p>
    </div>
  );

}

export default CustomColumnCharts;



