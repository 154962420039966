import { useState, useEffect } from "react";

import leftArrowImg from '../Assets/Images/left-arrow.png';
import rightArrowImg from '../Assets/Images/right-arrow.png';
import leftArrowMobileImg from '../Assets/Images/left-arrow-mobile.png';
import rightArrowMobileImg from '../Assets/Images/right-arrow-mobile.png';

import useWindowDimensions from '../Hooks/useWindowDimensions';
import StackedBarChart from "./Charts/StackedBarChart";

const CustomStackedColumnChart = (props) => {

  console.log(props);

  const pageId = props.chartId;
  const { windowWidth } = useWindowDimensions();
  const [rightArrow, setRightArrow] = useState(rightArrowImg);
  const [leftArrow, setLeftArrow] = useState(leftArrowImg);
  const [rightText, setRightText] = useState();

  useEffect(() => {
    if (windowWidth < 831) {
      setRightArrow(rightArrowMobileImg);
      setLeftArrow(leftArrowMobileImg);
      setRightText(<text style={{ textAnchor: 'middle', fontSize: windowWidth < 831 ? '13px' : '17px', fontWeight: windowWidth < 831 ? '600' : '300', transform: `translate(${windowWidth < 831 ? "74px, -4px" : "220px, -15px"} )` }} >
        <tspan x="0" dy="1.2em">A Lean Church</tspan>
      </text>);
    } else {
      setRightArrow(rightArrowImg);
      setLeftArrow(leftArrowImg);
      if (pageId === "701") {
        let persqft = 45;
        let persqftScore = props.variables.find(obj => obj.variable == "persqft");
        if (persqftScore) {
          persqft = persqftScore.score;
        }
        // console.log("persqft", persqft);
        setRightText(<text style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300', transform: 'translate(220px, -15px)' }} >
          <tspan x="0" dy="1.2em">A Lean Church needs around</tspan>
          <tspan x="0" dy="1.2em">{persqft} square feet per seat.</tspan>
        </text>);
      } else if (pageId === "707") {
        setRightText(<text style={{ textAnchor: 'start', fontSize: '17px', fontWeight: '300', transform: 'translate(130px, -15px)' }} >
          <tspan x="0" dy="1.2em">A Lean Church needs 2 seats</tspan>
          <tspan x="0" dy="1.2em">per parking spot.</tspan>
        </text>);
      }
    }
    // if (windowWidth < 831) {
    //   setRightArrow(rightArrowMobileImg);
    //   setLeftArrow(leftArrowMobileImg);
    //   setRightText("A Lean Church");
    // } else {
    //   setRightArrow(rightArrowImg);
    //   setLeftArrow(leftArrowImg);
    //   if (pageId === "701") {
    //     let persqft = 45;
    //     let persqftScore = props.variables.find(obj => obj.variable == "persqft");
    //     if(persqftScore) {
    //       persqft = persqftScore.score;
    //     }
    //     // console.log("persqft", persqft);
    //     setRightText(`A Lean Church needs around ${persqft} square feet per seat.`);
    //   } else if (pageId === "707") {
    //     setRightText("A Lean Church needs 2 seats per parking spot.");
    //   }
    // }
  }, [windowWidth, pageId, props.variables]);

  let sqftperseat = 45;
  let persqft = 45;
  let seatslean = 100;
  let seats = 75;

  let score = 75;
  let total = 100;

  let difference = total - score;
  let scorePercent = (score / total) * 100;
  // score bar is 200px height & score is in % so multiple by 2 to get px height for scoreBar
  let scoreBar = scorePercent * 2;
  let differencePercent = (difference / total) * 100;
  // difference bar is 200px height & score is in % so multiple by 2 to get px height for differenceBar
  let differenceBar = differencePercent * 2;


  let leftText = "Your church";
  let showParkingScoreDisclaimer = false;
  let showSeatingScoreNote = false;

  let chartHeader;
  let chartSubHeader;
  let chartBottomText;
  let scoreGreaterThanTotal = false;
  let scoreEqualToTotal = false;
  // score based text changes
  if (pageId === "701") {

    let sqftperseatScore = props.variables.find(obj => obj.variable == "sqftperseat");
    if (sqftperseatScore) {
      sqftperseat = sqftperseatScore.score;
    }

    let seatsleanScore = props.variables.find(obj => obj.variable == "seatslean");
    if (seatsleanScore) {
      seatslean = seatsleanScore.score;
    }

    let seatsScore = props.variables.find(obj => obj.variable == "seats");
    if (seatsScore) {
      seats = seatsScore.score;
    }

    let persqftScore = props.variables.find(obj => obj.variable == "persqft");
    if (persqftScore) {
      persqft = persqftScore.score;
    }
    console.log(persqft);

    score = seats;
    total = seatslean;
    // if score > total then swap values for chart correction
    // otherwise percentages will be wrong since it is score / total * 100 
    if (seats > seatslean) {
      score = seatslean;
      total = seats;
      scoreGreaterThanTotal = true;
    } else {
      scoreGreaterThanTotal = false;
    }
    scoreEqualToTotal = total == score;
    difference = total - score;
    scorePercent = (score / total) * 100;
    // score bar is 200px height & score is in % so multiple by 2 to get px height for scoreBar
    differencePercent = (difference / total) * 100;
    // difference bar is 200px height & score is in % so multiple by 2 to get px height for differenceBar
    scoreBar = scorePercent * 2;
    differenceBar = differencePercent * 2;

    console.log("score", score); console.log("total", total);
    console.log("difference", difference); console.log("scorePercent", scorePercent);
    console.log("differencePercent", differencePercent);

    chartBottomText = "Number of Seats";
    chartHeader = `Your church uses <span style="border: 4px solid #FFDD61; border-radius: 50%; padding: 4px 6px;">${Math.round(sqftperseat)}</span> sq ft per seat`;
    if (difference > 0 && !scoreGreaterThanTotal) {
      chartSubHeader = `<span>As per the Lean Facility Model,</span><br/>a more efficient building could help accommodate ${Math.round(difference)} more seats.`;
    } else if (scoreGreaterThanTotal && difference > 0) {
      showSeatingScoreNote = true;
      chartSubHeader = `<span>In a Lean Facility Model,</span><br/>you would have ${Math.round(difference)} fewer seats.`;
    } else {
      chartSubHeader = "";
    }
    if (windowWidth < 831) chartSubHeader = chartSubHeader.replaceAll("<br/>", " ");

  } else if (pageId === "707") {

    let spot;
    let leanspot;

    let spotScore = props.variables.find(obj => obj.variable == "spot");
    if (spotScore) {
      spot = spotScore.score;
    }

    let leanspotScore = props.variables.find(obj => obj.variable == "leanspot");
    if (leanspotScore) {
      leanspot = leanspotScore.score;
    }

    score = spot;
    total = leanspot;
    // if score > total then swap values for chart correction
    // otherwise percentages will be wrong since it is score / total * 100 
    if (spot > leanspot) {
      score = leanspot;
      total = spot;
      scoreGreaterThanTotal = true;
    } else {
      scoreGreaterThanTotal = false;
    }
    scoreEqualToTotal = total == score;
    difference = total - score;
    scorePercent = (score / total) * 100;
    // score bar is 200px height & score is in % so multiple by 2 to get px height for scoreBar
    scoreBar = scorePercent * 2;
    differencePercent = (difference / total) * 100;
    // difference bar is 200px height & score is in % so multiple by 2 to get px height for differenceBar
    differenceBar = differencePercent * 2;

    console.log("score", score); console.log("total", total);
    console.log("difference", difference); console.log("scorePercent", scorePercent);
    console.log("differencePercent", differencePercent);

    chartBottomText = "Number of Parking Spots";
    if (difference > 0 && !scoreGreaterThanTotal) {
      showParkingScoreDisclaimer = true;
      chartHeader = "You do not have enough parking spots.";
      chartSubHeader = `You need to add ${Math.round(difference)} more parking spots<br/><span style="font-weight: 400;">in order to maximize your seat count.*</span>`;
    } else if (scoreGreaterThanTotal && difference > 0) {
      chartHeader = "You have more parking than you need.";
      chartSubHeader = `<span style="font-weight: 400;">You have more parking than you need and </span><br/>can add ${Math.round(difference)} more seats before it becomes a lid to growth.`;
    } else {
      chartHeader = "You have the perfect amount of parking.";
      chartSubHeader = ``;
    }
    if (windowWidth < 831) chartSubHeader = chartSubHeader.replaceAll("<br/>", " ");
  }

  const getMobileTextForChart = () => {
    if (pageId == "701") {
      let persqft = 45;
      let persqftScore = props.variables.find(obj => obj.variable == "persqft");
      if (persqftScore) {
        persqft = persqftScore.score;
      }
      // console.log("persqft", persqft);
      return (<p style={{ fontSize: '17px', fontWeight: '300', textAlign: 'center' }} >
        A Lean Church needs around <br />
        {persqft} square feet per seat.
      </p>);
    } else if (pageId == "707") {
      return (<p style={{ fontSize: '17px', fontWeight: '300', textAlign: 'center' }} >
        A Lean Church needs 2 seats <br />
        per parking spot.
      </p>);
    }
  }

  return (
    <div style={{ margin: "15px auto" }}>

      {/* Total Score Bar with Right Text */}
      <div className="chart-container custom-chart-container" style={{ padding: "30px 15px 5px" }}>
        <h2 dangerouslySetInnerHTML={{ __html: chartHeader }}></h2>
        {/* <div className="flex-container" style={{ height: "200px", flexDirection: scoreGreaterThanTotal ? "column-reverse" : "column" }}>
          <div className="score-bar" style={{ height: `${scoreGreaterThanTotal ? scoreBar : differenceBar}px`, backgroundColor: scoreEqualToTotal ? "#FFDD61" : scoreGreaterThanTotal ? "#464646" : "#FFDD61", position: "relative", borderTop: scoreGreaterThanTotal ? "2px dashed #FFDD61" : "none" }}>
            <div className="right-side-info">
              <div className="score-arrow">
                <span>{scoreGreaterThanTotal ? Math.round(score) : Math.round(total)}</span>&nbsp;&nbsp;<img src={rightArrow} alt="right arrow" height="12px" width="auto" />
              </div>
              <div className="score-text">
                <span className="chart-side-text light-weight">{rightText}</span></div>
            </div>
          </div>
          
          <div className="score-bar" style={{ height: `${scoreGreaterThanTotal ? differenceBar : scoreBar}px`, backgroundColor: scoreEqualToTotal ? "#FFDD61" : "#464646", position: "relative" }}>
            <div className="left-side-info">
              <div className="score-arrow">
                <img src={leftArrow} alt="left arrow" height="12px" width="auto" /> <span>{scoreGreaterThanTotal ? Math.round(total) : Math.round(score)}</span>
              </div>
              <div className="score-text"><span className="chart-side-text">{leftText}</span></div>
              {showSeatingScoreNote && 
                <div className="score-note">
                  <strong>Note:</strong> This may indicate you don't have all the same spaces as a typical church, i.e., offices, or you do ministry in Southern California and have an outdoor lobby.
                </div>}
            </div>
          </div>
        </div> */}

        {windowWidth < 831 ? getMobileTextForChart() : null}

        <StackedBarChart
          height={275}
          width={275}
          pageId={pageId}
          barLeftValue={total}
          barRightValue={score}
          windowWidth={windowWidth}
          barLeftColor='#464646'
          barRightColor='#FFDD61'
          rightText={rightText}
          bottomAxisText={pageId === "701" ? "Number of Seats" : "Number of Parking Spots"}
          showNote={pageId === "701"}
        />
        {/* <p className="chart-bottom-text">{chartBottomText}</p> */}
        {chartSubHeader == "" ? null : <p className="chart-subheader" style={{ fontWeight: pageId == 707 ? 700 : 400 }} dangerouslySetInnerHTML={{ __html: chartSubHeader }}></p>}
        {showParkingScoreDisclaimer ? <p style={{ fontSize: "18px", lineHeight: "25px", fontWeight: 300, margin: "2px auto", textAlign: "center" }}>*Urban churches with public transit can violate this rule.</p> : null}
      </div>


    </div>
  );

}

export default CustomStackedColumnChart;