import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TextField, Button, Box, Stepper,RadioGroup,Radio,FormControlLabel } from "@mui/material";
import AdditionalQuestionContext from "../Store/AdditionalQuestionContext";
import prevArrow from '../Assets/Images/prev-arrow.svg';
import useWindowDimensions from "../Hooks/useWindowDimensions";

const FormPage = () => {

	const { id, pageId } 										= useParams();
	const {windowWidth} 										= useWindowDimensions();
	const navigate 													= useNavigate();
	const [searchParams, setSearchParams]   = useSearchParams();
	const addCtx														= useContext(AdditionalQuestionContext);

	const [avgWeeklyAttd, setAvgWeeklyAttd] = useState(addCtx?.form1Details?.avgWeeklyAttd);
	const [zipCode, setZipCode]							= useState(addCtx?.form1Details?.zipCode);
	const [seatingError, setSeatingError] = useState(false)
	const [formAnswer, setFormAnswer] = useState("");

	const [formQuestions, setFormQuestions]	= useState("");
	const [zipCodeError, setZipCodeError]		= useState(false);
	const [weeklyAttdError, setWeeklyAttdError] = useState(false);
	const [zipCodeValid, setZipCodeValid] 	= useState(true);
	const [weeklyAttdValid, setWeeklyAttdValid] = useState(true);
	const [activeStep, setActiveStep]         = useState(1);
	const [parkingSpots, setParkingSpots]     = useState("");
	const [parkingSpotsError, setParkingSpotsError] = useState(false);
	const [parkingSpotsValid, setParkingSpotsValid] = useState(true);
	const [squareFootage, setSquareFootage] 	= useState("");
	const [squareFootageError, setSquareFootageError] = useState(false);
	const [squareFootageValid, setSquareFootageValid] = useState(true);


	useEffect(()=>{
		console.log(formAnswer,"formAnswer")
	},[formAnswer])

	const styles = theme => ({
		radio: {
		  '&$checked': {
			color: '#FFDD61'
		  }
		},
		checked: {}
	  })

	useEffect(() => {
		const updateForm1CtxDetails = (fieldName, value) => {
      if (value !== addCtx.form1Details?.[fieldName]) {
        addCtx.addForm1Details(prev => ({ ...prev, [fieldName]: value }));
      }
    };

    updateForm1CtxDetails("avgWeeklyAttd", avgWeeklyAttd);
    updateForm1CtxDetails("zipCode", zipCode);

  }, [avgWeeklyAttd, zipCode]);


	useEffect(() => {
    let form1Details = addCtx.form1Details;

    if (form1Details?.avgWeeklyAttd?.length)	setAvgWeeklyAttd(form1Details.avgWeeklyAttd);
    if (form1Details?.zipCode?.length)				setZipCode(form1Details.zipCode);

  }, [addCtx.form1Details]);
	
	console.log("addCtx.form1Details", addCtx.form1Details);
	console.log("formQuestions", formQuestions);


	const showAnswers = (answer, pageId) => {
		switch (pageId) {
			case "700": setSquareFootage(answer); break;
			case "706": setParkingSpots(answer); break;
			default:
				break;
		}
	}

	useEffect(() => {
		
		const getFormQuestions = () => {
			fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
				method: 'GET',
				mode: 'cors',
				headers: { 'Content-Type': 'application/json' }
			}).then(response => {
				return response.json();
			}).then(data => {
				if(data.status == "success") {
					console.log(data);
					setFormQuestions(data?.data.questions);
					if (data?.data.questions[0]?.hasOwnProperty('answer')) {
						setFormAnswer(data?.data.questions[0]?.answer);
						showAnswers(data?.data.questions[0]?.answer, pageId);
					}
					// setIsLoading(false);
				} else {
					// setOpenSnackbar(true);
				}
			}).catch((err) => console.error("Error while fetching form questions...", err));
		}
		
		getFormQuestions();

	}, [id, pageId]);


	const submitForm = () => {
		
		let jsonBody = {};
		if(pageId == 698) {
			jsonBody = { 
				pageId: pageId,
				questions: [
					{
						"id": 860,
						"answer": formAnswer,
						"answerTxt": null
					}
				],
			}
		} else if(pageId == 700) {
			jsonBody = { 
				pageId: pageId,
				questions: [
					{
						"id": 861,
						"answer": squareFootage,
						"answerTxt": null
					}
				],
			}
		} else if(pageId == 702) {
			jsonBody = { 
				pageId: pageId,
				questions: [
					{
						"id": 862,
						"answer": formAnswer,
						"answerTxt": null
					}
				],
			}
		} else if(pageId == 704) {
			jsonBody = { 
				pageId: pageId,
				questions: [
					{
						"id": 863,
						"answer": formAnswer,
						"answerTxt": null
					}
				],
			}
		} else if(pageId == 706) {
			jsonBody = { 
				pageId: pageId,
				questions: [
					{
						"id": 864,
						"answer": parkingSpots,
						"answerTxt": null
					}
				],
			}
		} 
		
		fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
        if(pageId == 698) {
					navigate("/charts/" + id + "/699?" + searchParams, { replace: true });
				} else if(pageId == 700) {
					navigate("/charts/" + id + "/701?" + searchParams, { replace: true });
				} else if(pageId == 702) {
					navigate("/charts/" + id + "/703?" + searchParams, { replace: true });
				} else if(pageId == 704) {
					navigate("/charts/" + id + "/705?" + searchParams, { replace: true });
				} else if(pageId == 706) {
					navigate("/charts/" + id + "/707?" + searchParams, { replace: true });
				}
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to send form details...", err));
	}

	const handleSubmit = () => {
		submitForm();
		
		// if(zipCode.length <= 7 && zipCode.length >= 5 && +avgWeeklyAttd < 100000 && +avgWeeklyAttd > 99) {
		// 	submitFirstForm(e)
		// } else {
		// 	showErrors();
		// }

		if (pageId == 700) {
			Number.isInteger(parseInt(formAnswer)) ? setSeatingError(false) : setSeatingError(true)
		}
	}

	useEffect(()=>{
		console.log(seatingError,"seatingError")
	},[seatingError])

	const handlePrevious = () => {
		if (pageId == 698) {
			navigate("/first-form/" + id + "/695?" + searchParams, { replace: true });
		} else {
			navigate("/charts/" + id + "/" + (parseInt(pageId) - 1) + "?" + searchParams, { replace: true });
		}
	}

	const showErrors = () => {
		if(!zipCode) {
			setZipCodeError(true);
		}

		if(zipCode.length > 7 || zipCode.length < 5 ) {
			setZipCodeValid(false);
		}
		
		// if(+avgWeeklyAttd < 100 || +avgWeeklyAttd >= 100000) {
		// 	setWeeklyAttdValid(false);
	  // }

		if (!parkingSpots || isNaN(parkingSpots)) {
			setParkingSpotsError(true);
		}
		if (parseInt(parkingSpots) < 10) {
			setParkingSpotsValid(false);
		}
		if (isNaN(squareFootage)) {
			setSquareFootageValid(false);
		}
		if (!isNaN(squareFootage) && parseInt(squareFootage) < 1) {
			setSquareFootageError(true);
		}

	}


	const showHintText = (field) => {
		if(field == "weeklyAttendance") {
			if(weeklyAttdError) {
				return <div className="error-text">Please confirm your avg. weekly attendance.</div>
			}
			if(!weeklyAttdValid) {
				return <div className="error-text">Maximum avg. weekly attendance can range from 100 to 100,000.</div>
			}
		}

		if(field == "zipCode") {
			if(zipCodeError) {
				return <div className="error-text">Please confirm your ZIP code.</div>
			}
			if(!zipCodeValid) {
				return <div className="error-text">Invalid ZIP code.</div>
			}
		}

		if (field == "parkingSpots") {
			if (parkingSpotsError) return <div className="error-text">Please confirm your parking spots.</div>
			if (!parkingSpotsValid) return <div className="error-text">Invalid. Must be at least two digits.</div>
		}
		if (field == "squareFootage") {
			if (squareFootageError) return <div className="error-text">Please confirm your square footage.</div>
			if (!squareFootageValid) return <div className="error-text">Please enter a valid response.</div>
		}

	}

	const removeHintText = (field, e) => {
		// if (e.target.closest(".form-detail")) {
		// 	document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#4A4A4A")
		// 	e.target.closest(".form-detail").querySelector(".input-title").style.color = "#ED2028";
		// }

		if(field == "weeklyAttendance") {
			setWeeklyAttdError(false);
			setWeeklyAttdValid(true);
		}

		if(field == "zipCode") {
			setZipCodeError(false);
			setZipCodeValid(true);
		}
		if (field == "parkingSpots") {
			setParkingSpotsError(false);
			setParkingSpotsValid(true);
		}
		if (field == "squareFootage") {
			setSquareFootageError(false);
			setSquareFootageValid(true);
		}
	}

	const showForm = () => {
    switch(pageId) {
      case "698":
        return <>
					<div className="form-Page">
						<h1>SITE UTILIZATION</h1>
					</div>
					<Box sx={{ width: '100%' }}>
						<div className="progress-indicator"><strong>1</strong>/5</div>
					</Box>
					<div className="form">
						<div className="form-detail detail">
							<p>Many effective churches are not only efficiently building out their site, but they are also deploying their exterior green space in a community-facing way.</p>
						</div>
						
						<div className="form-detail question">
							<h2>How many seats does your facility (approx.) have?</h2>
						</div>

						<div className="form-detail zip">
							<TextField className="form-details-text" placeholder="E.g. 600" value={formAnswer} onFocus={(e) => removeHintText("zipCode", e)} onChange={(e) => setFormAnswer(e.target.value)}></TextField>
							{showHintText("zipCode")}
						</div>
					</div>
					<div className="cta">
						<Button className="cta-btn prev-btn" onClick={(e) => handlePrevious()}> <img src={prevArrow} alt="previous button" /> {
							windowWidth >= 831 ? "PREV" : ""
						} </Button>
						<Button className="cta-btn next-btn" disabled={formAnswer ? false : true} onClick={(e) => handleSubmit(e)}>NEXT</Button>
					</div>
				</>;

			case "700":
				return <>
					<div className="form-Page">
						<h1>SEATING</h1>
					</div>
					<Box sx={{ width: '100%' }}>
						<div className="progress-indicator"><strong>2</strong>/5</div>
					</Box>
					<div className="form">
						<div className="form-detail detail">
							<p>Total building square footage should include areas for worship, children's ministry, a mid-size gathering space for youth or fellowships of other types, offices, lobbies, and cafes.</p>
						</div>
						
						<div className="form-detail question">
							<h2>What is the total square footage (approx.) of your building?*</h2>
						</div>

						<div className="form-detail zip" >
							<TextField className="form-details-text" placeholder="E.g. 30000" value={squareFootage} onFocus={(e) => {
								removeHintText("squareFootage", e);
								setSquareFootageError(false);
							}}
							onClick={() => {
								setSquareFootageError(false);
							}}
							onChange={(e) => {
								setSquareFootage(e.target.value);
							}}></TextField> <span className="sqft">sq ft</span>
							{showHintText("squareFootage")} 
						</div>
					</div>
					<div className="cta">
						<Button className="cta-btn prev-btn" onClick={(e) => handlePrevious(e)}> <img src={prevArrow} alt="previous button" /> {
							windowWidth >= 831 ? "PREV" : ""
						} </Button>
						<Button className="cta-btn next-btn" disabled={squareFootage ? false : true} onClick={(e) => {
							showErrors();
							if (!isNaN(squareFootage) && parseInt(squareFootage) > 0) {
								handleSubmit(e);
							}
						}}>NEXT</Button>
					</div>
					<div className="note">
						<p className="note-text">
						{ windowWidth >= 831 ?  "*For additional gathering spaces, such as the gym/fellowship hall, five square footage can be added." : <>*For additional gathering spaces, such as the gym/ &nbsp;fellowship hall, five square footage can be added.</>}
						</p>
					</div>
				</>;

			case "702":
				return <>
					<div className="form-Page">
						<h1>STORAGE</h1>
					</div>
					<Box sx={{ width: '100%' }}>
						<div className="progress-indicator"><strong>3</strong>/5</div>
					</Box>
					<div className="form">
						<div className="form-detail detail">
							<p>Churches require a lot of storage to make spaces multi-functional. Storing the wrong things in the wrong places makes your facility inefficient.</p>
						</div>
						
						<div className="form-detail question">
							<h2>What is your current storage situation?</h2>
						</div>

						<div className="form-detail radio-options">
							{/* <TextField className="form-details-text" placeholder="E.g. 600" value={formAnswer} onFocus={(e) => removeHintText("zipCode", e)} onChange={(e) => setFormAnswer(e.target.value)}></TextField> */}
							<RadioGroup className="radio-options-group"
								aria-labelledby="demo-controlled-radio-buttons-group"
								name="controlled-radio-buttons-group"
								value={formAnswer}
								onChange={(e)=>{{setFormAnswer(e.target.value)}}}
							>
								<FormControlLabel value="7" style={{fontWeight:formAnswer==='7'?"600":"400"}} control={<Radio />} label="We have enough storage space in key locations that rooms and spaces function effectively." />
								<FormControlLabel value="8" style={{fontWeight:formAnswer==='8'?"600":"400"}} control={<Radio />} label="We have a lot of storage space, but it is always packed or in the wrong location." />
								<FormControlLabel value="9" style={{fontWeight:formAnswer==='9'?"600":"400"}} control={<Radio />} label="We do not have enough storage space, and all our stuff on site is Mission-critical." />
								<FormControlLabel value="10" style={{fontWeight:formAnswer==='10'?"600":"400"}} control={<Radio />} label="We do not have enough storage space, yet it is packed with too much junk." />
							</RadioGroup>
							{showHintText("zipCode")}
						</div>
					</div>
					<div className="cta storage-cta">
						<Button className="cta-btn prev-btn" onClick={(e) => handlePrevious(e)}> <img src={prevArrow} alt="previous button" /> {
							windowWidth >= 831 ? "PREV" : ""
						} </Button>
						<Button className="cta-btn next-btn" disabled={formAnswer ? false : true} onClick={() => handleSubmit()}>NEXT</Button>
					</div>
				</>;

			case "704":
				return <>
					<div className="form-Page">
						<h1>MINISTRY AREA RATIO (M.A.R.)</h1>
					</div>
					<Box sx={{ width: '100%' }}>
						<div className="progress-indicator"><strong>4</strong>/5</div>
					</Box>
					<div className="form">
						<div className="form-detail detail">
							<p>Your M.A.R. measures the amount of space used for ministry, i.e., ministry programmable square footage to the overall square footage of the building.</p>
						</div>
						
						<div className="form-detail question">
							<h2>How much of your dedicated ministry space (approx.) is used multiple times per week?</h2>
						</div>

						<div className="form-detail radio">
							{/* <TextField className="form-details-text" placeholder="E.g. 600" value={formAnswer} onFocus={(e) => removeHintText("zipCode", e)} onChange={(e) => setFormAnswer(e.target.value)}></TextField> */}
							<RadioGroup
								aria-labelledby="demo-controlled-radio-buttons-group"
								name="controlled-radio-buttons-group"
								className="mar-radio-options"
								value={formAnswer}
								onChange={(e)=>{{
									setFormAnswer(e.target.value)
								}}}
								onInput={()=>{
									handleSubmit()
								}}
							>
								<FormControlLabel value="25" control={<Radio />} label="25%" />
								<FormControlLabel value="50" control={<Radio />} label="50%" />
								<FormControlLabel value="75" control={<Radio />} label="75%" />
								<FormControlLabel value="80" control={<Radio />} label="80% or more" />
							</RadioGroup>
							{showHintText("zipCode")}
						</div>
					</div>
					<div className="cta">
						<Button className="cta-btn prev-btn" onClick={(e) => handlePrevious(e)}> <img src={prevArrow} alt="previous button" /> {
							windowWidth >= 831 ? "PREV" : ""
						} </Button>
						<Button className="cta-btn next-btn" disabled={formAnswer ? false : true} onClick={() => handleSubmit()}>NEXT</Button>
					</div>
				</>;

			case "706":
				return <>
					<div className="form-Page">
						<h1>PARKING</h1>
					</div>
					<Box sx={{ width: '100%' }}>
						<div className="progress-indicator"><strong>5</strong>/5</div>
					</Box>
					<div className="form">
						<div className="form-detail detail">
							<p>When 80% of the parking area is unavailable, weekend attendance may plateau until more space is available.</p>
						</div>
						
						<div className="form-detail question">
							<h2>How many parking spots (approx.) does your facility have?</h2>
						</div>

						<div className="form-detail zip" style={{ flexDirection: "column" }}>
							<TextField className="form-details-text" placeholder="E.g. 1200" value={parkingSpots} onFocus={(e) => removeHintText("parkingSpots", e)} onChange={(e) => setParkingSpots(e.target.value)}></TextField>
							{showHintText("parkingSpots")}
						</div>
					</div>
					<div className="cta">
						<Button className="cta-btn prev-btn" onClick={(e) => handlePrevious(e)}> <img src={prevArrow} alt="previous button" /> {
							windowWidth >= 831 ? "PREV" : ""
						} </Button>
						<Button className="cta-btn next-btn" disabled={parkingSpots ? false : true} onClick={(e) => {
							showErrors();
							if (parseInt(parkingSpots) > 9) {
								handleSubmit(e);
							}}}>NEXT</Button>
					</div>
				</>;

      default:
        return '';
    }
  }

	const containerSize = () => {
    switch(pageId) {
			case "702":
				return "form-Page-container wider";
			default:
				return "form-Page-container";
		}
	}

	return (
		<div className={containerSize()}>
			{showForm()}
		</div>
	)
}

export default FormPage;
