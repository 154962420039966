import { scaleBand, scaleLinear } from 'd3';
import React from 'react'

const BarChart = (props) => {

  const { height, width, pageId, max, score, windowWidth, bracketMin, bracketMax, bracketColor, barColor = '#FFDD61', bottomAxisText, scoreTextColor } = props;

  let chartIdealTxt

  const margin = { top: 20, right: 20, bottom: 25, left: 25 }
  const innerHeight = height - margin.top - margin.bottom
  const innerWidth = width - margin.left - margin.right

  var xScale = scaleBand()
    .domain(["1", "2", "3", "4", "5"])         // This is what is written on the Axis: from 0 to 100
    .range([0, innerWidth]);       // This is where the axis is placed: from 100px to 800px


  var yScale = scaleLinear()
    .domain([0, max < 150 ? 150 : max])         // This is what is written on the Axis: from 0 to 100
    .range([innerHeight, 0])




  const AxisLeft = ({ yScale, innerWidth }) =>
    yScale.ticks(max < 100 ? 100 : max).map(tickValue =>
      <g className='tick' key={tickValue} transform={`translate(0, ${yScale(tickValue)})`}>

        {
          tickValue == bracketMin || tickValue == bracketMax
            ? <>
              <text style={{ textAnchor: 'middle', fontSize: '13px' }} y={'5px'} x={'-10px'}>{tickValue}%</text>
            </>
            : null
        }
      </g>
    )

  const getScoreTextForSvg = () => {
    if (pageId === "699") {
      chartIdealTxt = <>
        <text transform={`translate(${150}, ${-120})`} style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300' }} >
          <tspan x="0" dy="1.2em">A Lean Church Facility can</tspan>
          <tspan x="0" dy="1.2em">achieve 100 seats per acre.</tspan>
          <tspan x="0" dy="1.2em">&nbsp;</tspan>
          <tspan x="0" dy="1.2em">A low score here may indicate</tspan>
          <tspan x="0" dy="1.2em">room for future growth!</tspan>
        </text>
      </>;
      if (Math.round(score) == 100) {
        chartIdealTxt = <>
          <text transform={`translate(${150}, ${-50})`} style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300' }} >
            <tspan x="0" dy="1.2em">A Lean Church Facility can</tspan>
            <tspan x="0" dy="1.2em">achieve 100 seats per acre.</tspan>
          </text>
        </>
      } else if (Math.round(score) > 100) {
        chartIdealTxt = <>
          <text transform={`translate(${150}, ${-150})`} style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300' }} >
            <tspan x="0" dy="1.2em">A Lean Church Facility can</tspan>
            <tspan x="0" dy="1.2em">achieve 100 seats per acre.</tspan>
            <tspan x="0" dy="1.2em">&nbsp;</tspan>
            <tspan x="0" dy="1.2em">A score above 100 may</tspan>
            <tspan x="0" dy="1.2em">indicate you operate in an</tspan>
            <tspan x="0" dy="1.2em">urban setting or provide off-</tspan>
            <tspan x="0" dy="1.2em">site parking.</tspan>
          </text>
        </>;
      }
    } else if (pageId === "705") {

      if (Math.round(score) >= 80) {
        chartIdealTxt = <>
          <text transform={`translate(${150}, ${-50})`} style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300' }} >
            <tspan x="0" dy="1.2em">Your building is used a lot!</tspan>
            <tspan x="0" dy="1.2em">Grab a Red Bull.</tspan>
          </text>
        </>
      } else {
        chartIdealTxt = <>
          <text transform={`translate(${150}, ${-70})`} style={{ textAnchor: 'middle', fontSize: '17px', fontWeight: '300' }} >
            <tspan x="0" dy="1.2em">A Lean Church needs to specifically</tspan>
            <tspan x="0" dy="1.2em">design its spaces for adaptability</tspan>
            <tspan x="0" dy="1.2em">and community use.</tspan>
          </text>
        </>;
      }
    }
    return chartIdealTxt
  }

  return (
    <div>
      <svg style={{ overflow: 'visible' }} height={height} width={width} >
        <g transform={`translate(${margin.left}, ${margin.top})`}>

          {/* ------------------------ x and y axis ------------------------ */}
          <g className='axis'>
            <line strokeWidth={1} x1="10" y1="0" x2="10" y2={innerHeight} stroke="black" />
            <line strokeWidth={1} x1={10} y1={innerHeight} x2={innerWidth} y2={innerHeight} stroke="black" />
            <AxisLeft yScale={yScale} />
          </g>

          {/* ------------------------ x and y bars ------------------------ */}
          <g className='bars'>
            <rect fill={barColor} y={yScale(score > 120 ? max : score)} height={innerHeight - yScale(score > 120 ? max : score) - 1} x={xScale("3")} width={xScale.bandwidth()}></rect>
            <rect fill={bracketColor ? bracketColor : barColor} opacity={0.2} y={yScale(bracketMax)} height={yScale(bracketMin) - yScale(bracketMax)} x={10} width={innerWidth - 10}></rect>
            <line x1="11" y1={yScale(bracketMin)} x2={innerWidth} y2={yScale(bracketMin)} stroke={bracketColor ? bracketColor : barColor} stroke-dasharray="3" />
          </g>

          {/* ------------------------ score text and arrow ------------------------ */}
          {
            windowWidth < 831
              ? <g transform={`translate(${innerWidth}, ${yScale(85)}), rotate(-95)`}>
                <svg width="130" height="51" viewBox="0 0 160 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M158.055 2C158.055 6.87889 158.209 11.7204 157.082 16.5078C154.723 26.5367 149.357 36.1711 141.741 43.1083C135.414 48.8714 127.015 52.4359 118.98 55.1141C111.312 57.6703 102.972 59.0843 94.8988 59.2146C87.4075 59.3354 80.2195 59.225 72.8678 57.6682C67.8413 56.6038 63.1062 54.6402 58.4469 52.508C54.3307 50.6243 50.5848 47.9407 46.6322 45.7319C42.3262 43.3256 38.2117 40.7591 34.0703 38.1044C29.6063 35.2428 26.6264 32.2146 22.1875 29.2889C19.1991 27.3193 16.6875 24.7889 13.1875 22.2889C9.6875 19.7889 8.05379 18.5854 6.06248 16.6989C5.24567 15.9251 3.87357 14.6662 2.93506 14.197" stroke="#FFDD61" stroke-width="3" stroke-linecap="round" />
                  <path d="M2.9375 14.1969C2.9375 17.8575 1.99927 21.3387 1.99927 24.8302" stroke="#FFDD61" stroke-width="3" stroke-linecap="round" />
                  <path d="M3.2508 13.8843C5.9149 13.8843 8.579 13.8843 11.2431 13.8843C13.2826 13.8843 15.3297 13.2588 17.3242 13.2588" stroke="#FFDD61" stroke-width="3" stroke-linecap="round" />
                </svg>
              </g>
              : <g transform={`translate(${innerWidth + 5}, ${yScale(93)})`}>
                {getScoreTextForSvg()}
                <svg width="160" height="61" viewBox="0 0 160 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M158.055 2C158.055 6.87889 158.209 11.7204 157.082 16.5078C154.723 26.5367 149.357 36.1711 141.741 43.1083C135.414 48.8714 127.015 52.4359 118.98 55.1141C111.312 57.6703 102.972 59.0843 94.8988 59.2146C87.4075 59.3354 80.2195 59.225 72.8678 57.6682C67.8413 56.6038 63.1062 54.6402 58.4469 52.508C54.3307 50.6243 50.5848 47.9407 46.6322 45.7319C42.3262 43.3256 38.2117 40.7591 34.0703 38.1044C29.6063 35.2428 26.6264 32.2146 22.1875 29.2889C19.1991 27.3193 16.6875 24.7889 13.1875 22.2889C9.6875 19.7889 8.05379 18.5854 6.06248 16.6989C5.24567 15.9251 3.87357 14.6662 2.93506 14.197" stroke="#FFDD61" stroke-width="3" stroke-linecap="round" />
                  <path d="M2.9375 14.1969C2.9375 17.8575 1.99927 21.3387 1.99927 24.8302" stroke="#FFDD61" stroke-width="3" stroke-linecap="round" />
                  <path d="M3.2508 13.8843C5.9149 13.8843 8.579 13.8843 11.2431 13.8843C13.2826 13.8843 15.3297 13.2588 17.3242 13.2588" stroke="#FFDD61" stroke-width="3" stroke-linecap="round" />
                </svg>
              </g>
          }


          {/* ------------------------ x ans y axis text ------------------------ */}
          <g className='text'>
            <text style={{ textAnchor: 'middle', fontSize: '13px' }} x={innerWidth / 2} y={innerHeight + 15} >{bottomAxisText}</text>
            <text fill={scoreTextColor ? scoreTextColor : '#000'} style={{ textAnchor: 'middle', fontSize: '13px', fontWeight: '700' }} x={innerWidth / 2} y={yScale(score > 150 ? max : score) + 15} >{Math.trunc(score)}%</text>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default BarChart